import React, { useState } from 'react';

const ArtFetcher = () => {
  const [art, setArt] = useState(null);
  const departmentId = 11; // Ensure this is the correct department ID for European Paintings

  const fetchArtFromDepartment = async () => {
    try {
      const deptResponse = await fetch(`https://collectionapi.metmuseum.org/public/collection/v1/objects?departmentIds=${departmentId}`);
      const deptData = await deptResponse.json();
      
      if (deptData.total > 0) {
        const randomIndex = Math.floor(Math.random() * deptData.objectIDs.length);
        const objectId = deptData.objectIDs[randomIndex];
        
        const objectResponse = await fetch(`https://collectionapi.metmuseum.org/public/collection/v1/objects/${objectId}`);
        const objectData = await objectResponse.json();
        
        setArt(objectData);
      } else {
        console.error('No art pieces found in this department.');
      }
    } catch (error) {
      console.error("Error fetching data from the Met Museum API:", error);
    }
  };

  return (
    <div className="container text-center py-5">
      <button className="btn btn-primary" onClick={fetchArtFromDepartment}>Fetch Random European Painting</button>
      {art && (
        <div className="mt-4">
          <h2 className="h4">{art.title}</h2>
          {/* Check for period information and display a fallback message if not available */}
          <p>Period: {art.period ? art.period : 'Not available'}</p>
          <img src={art.primaryImage} alt={art.title} className="img-fluid mt-3" style={{padding: "20px"}} />
        </div>
      )}
    </div>
  );
};

export default ArtFetcher;
